import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Country,
  Movie,
  MovieStreamingListWrapper,
  PerformanceListWrapper,
  TabType,
  UserRole
} from '@usheru-hff/shared/domain';
import { ChannelService } from '@usheru-hff/shared/utils-channel';
import { LocationService } from '@usheru-hff/shared/utils-location';
import { TranslationsService } from '@usheru-hff/shared/utils-translation';
import { ENVIRONMENT, Environment } from '@environment';
import { MovieService } from './movie.service';
import { Observable, map, of, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShowtimeStreamingService {
  constructor(
    private channelService: ChannelService,
    @Inject(ENVIRONMENT) private env: Environment,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private translationService: TranslationsService,
    private movieService: MovieService
  ) {}

  location = this.locationService.userLocation?.address;
  country = this.locationService.selectedCountry.name;

  /**
   * It determines if streaming widget should be visible or not. It takes into account:
   *
   * @param movie
   * @returns
   */
  determineStreamingAvailability(movie: Movie): boolean {
    if (!this.isStreamingAvailableAtChannelLevel()) return false;
    if (this.isStreamingDisabledAtMovieLevel(movie)) return false;
    if (this.areStreamingLinksAvailableForSelectedCountry(movie)) return true;
    return this.areStreamingLinksAvailableSomewhere(movie);
  }

  /**
   * It determines if showtimes widget should be visible or not. It takes into account:
   * @param movie
   * @returns
   */
  determineInCinemasAvailability(movie: Movie): boolean {
    if (!this.areShowtimesAvailableAtChannelLevel()) return false;
    if (this.areShowtimesDisabledAtMovieLevel(movie)) return false;
    if (this.areShowtimesAvailableForSelectedCountry(movie)) return true;
    return this.areShowtimesAvailableSomewhere(movie);
  }

  determineFestivalsAvailability(movie) {
    return this.channelService.channel.userRoles.includes(UserRole.festivals) && movie?.movieFestivals;
  }

  determineEnquiriesAvailability() {
    return this.env.enquiries;
  }

  /**
   * Determine preference of display between showtimes, streaming, festivals, enquires widgets.
   * It is useful only when more than 1 widget is available.
   *
   * 1. Check url whereToWatch / section qparam
   * 2. Check preference at env level
   * 3. Check whether showtimes or streaming are available -- PENDING TO DO
   * 4.
   *
   * @param movie
   * @returns
   */
  determineShowtimeStreamingActiveType(movie, hideTabs?: TabType[] | undefined): Observable<TabType> {
    if (!movie) return of(null);
    const tabs = this.determineTabsAvailability(movie, hideTabs);
    if (tabs.length === 1) return of(tabs[0]);

    // Check url whereToWatch qparam
    const whereToWatch = this.getWhereToWatchQueryParamIfExists();

    if (whereToWatch) return of(whereToWatch as TabType);

    // check url section qparam
    const section = this.route.snapshot.queryParams['section'];
    if (section) return of(section);

    // Check preference at env level
    const envPreference = this.getEnvironmentPriority(movie);
    if (envPreference) return of(envPreference);

    // if we get to this point means the active type was not setup by preference or url param
    // so we need to determine it by availability.
    return this.getDefaultVisibility(movie, tabs);
  }

  private getWhereToWatchQueryParamIfExists(): string {
    if (this.env.appType === 'widget') {
      return this.getWidgetWhereToWatch();
    }
    return this.route.snapshot.queryParams['whereToWatch'];
  }

  private getWidgetWhereToWatch() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get('whereToWatch');
  }

  getDefaultVisibility(movie: Movie, tabs: TabType[]): Observable<TabType> {
    // get tabs available
    return this.checkShowtimes(movie, tabs).pipe(
      switchMap(result => (result ? of(result) : this.checkStreaming(movie, tabs))),
      switchMap(result => (result ? of(result) : this.checkFestivals(movie, tabs))),
      switchMap(result => (result ? of(result) : this.checkEnquiries(tabs)))
    );
  }

  getEnvironmentPriority(movie): TabType {
    if (!this.env.showtimeStreamingConfig) return null;
    if (this.env.showtimeStreamingConfig?.priorityByMovie) {
      return this.env.showtimeStreamingConfig.priorityByMovie.find(conf => conf.slug == movie.friendlyUrl)?.priority;
    } else {
      return this.env.showtimeStreamingConfig?.priority;
    }
  }

  private checkShowtimes(movie: Movie, tabs: TabType[]): Observable<TabType | null> {
    return this.areThereShowtimes(movie.id).pipe(
      map(thereShowtimes => (thereShowtimes && tabs.includes('in-cinemas') ? 'in-cinemas' : null))
    );
  }

  private checkStreaming(movie: Movie, tabs: TabType[]): Observable<TabType | null> {
    return this.areThereStreamingLinks(movie.id).pipe(
      map(streamingAvailable => (streamingAvailable && tabs.includes('watch-at-home') ? 'watch-at-home' : null))
    );
  }

  private checkFestivals(movie: Movie, tabs: TabType[]): Observable<TabType | null> {
    return of(this.determineFestivalsAvailability(movie) && tabs.includes('at-festivals') ? 'at-festivals' : null);
  }

  private checkEnquiries(tabs: TabType[]): Observable<TabType | null> {
    return of(this.determineEnquiriesAvailability() && tabs.includes('enquiries') ? 'enquiries' : null);
  }

  private areThereShowtimes(movieId: string): Observable<boolean> {
    const latitude = this.locationService.userLocation?.latitude;
    const longitude = this.locationService.userLocation?.longitude;
    if (latitude && longitude) {
      const filter: PerformanceListWrapper = { movie: [movieId], latitude, longitude };
      return this.movieService.getPerformancesGroup(filter).pipe(map(performances => performances.length > 0));
    } else {
      return of(false);
    }
  }
  private areThereStreamingLinks(movieId: string): Observable<boolean> {
    const streamingCountry = this.locationService.selectedCountry?.id;
    if (streamingCountry) {
      const filter: MovieStreamingListWrapper = { movie: [movieId], movieStreamingCountry: [streamingCountry] };
      return this.movieService.getStreamingLinks(filter).pipe(map(streamingLinks => streamingLinks.length > 0));
    } else {
      return of(false);
    }
  }

  /**
   * Funtion is triggered only if there are no performances found.
   */
  getShowtimesErrorMessage(selectedCountry: Country): { key: string; message: string } {
    // determine if partner has single or multiple territories.
    const territories: Country[] = this.channelService.channel.partner.locationRestrictions;
    const isSingleTerritory = territories.length === 1;
    const partnerTerritories = this.stringifyPartnerTerritories(territories);
    let errorMsg = '';
    let errorKey = '';
    if (isSingleTerritory) {
      // check if selectedLocationCountry is the same as the single territory.
      if (selectedCountry?.slug === territories[0].slug) {
        errorKey = 'public.commons.error.noShowtimes.singleTerritory';
        errorMsg = this.translationService.translate(
          'public.commons.noShowtimes',
          'Sorry, there are no showtimes currently available for this location.<br><small>Please check back later or try a different location.</small>'
        );
      } else {
        errorKey = 'public.commons.error.changeLocation';
        errorMsg = this.translationService.translate(
          'public.commons.error.changeLocation',
          'It looks like you are in _location_ and this page is for users in _partnerTerritories_.<br><small>Change your location for new results.</small>',
          { location: this.location, partnerTerritories }
        );
      }
    }
    if (!isSingleTerritory) {
      // check if selectedLocationCountry is within the available territories
      if (territories?.find(t => t.slug === selectedCountry?.slug) || territories.length === 0) {
        errorKey = 'public.commons.error.noShowtimes';
        errorMsg = this.translationService.translate(
          'public.commons.noShowtimes',
          'Sorry, there are no showtimes currently available for this location.<br><small>Please check back later or try a different location.</small>'
        );
      } else {
        errorKey = 'public.commons.error.changeLocation';
        errorMsg = this.translationService.translate(
          'public.commons.error.changeLocation',
          'It looks like you are in _location_ and this page is for users in _partnerTerritories_.<br><small>Change your location for new results.</small>',
          { location: this.location, partnerTerritories }
        );
      }
    }

    return { key: errorKey, message: errorMsg };
  }

  private stringifyPartnerTerritories(territories: Country[]) {
    return territories
      .map(t => this.translationService.translate(t.trMsg, t.name))
      .sort()
      .join(', ');
  }

  /**
   * Funtion is triggered only if there are no streaming links found.
   */
  getStreamingErrorMessage(selectedCountry: Country): string {
    // determine if partner has single or multiple territories.
    const territories: Country[] = this.channelService.channel.partner.locationRestrictions;
    const isSingleTerritory = territories.length === 1;
    let errorMsg = '';
    const partnerTerritories = this.stringifyPartnerTerritories(territories);
    if (isSingleTerritory) {
      // check if selectedCountry is the same as the single territory.
      if (selectedCountry?.slug === territories[0].slug) {
        errorMsg = this.translationService.translate(
          'public.commons.error.noStreamingAvailable.singleTerritory',
          'Sorry, there are no streaming platforms currently available for _country_.',
          { country: this.translationService.translate(selectedCountry.trMsg, selectedCountry.name) }
        );
      } else {
        errorMsg = this.translationService.translate(
          'public.commons.error.changeCountry',
          'It looks like you are in ${this.country} and this page is for users in ${partnerTerritories}.<br><small>Change your country for new results.',
          {
            country: this.translationService.translate(selectedCountry.trMsg, selectedCountry.name),
            partnerTerritories
          }
        );
      }
    }
    if (!isSingleTerritory) {
      // check if selectedCountry is within the available territories
      if (territories.find(t => t.slug === selectedCountry?.slug) || territories.length === 0) {
        errorMsg = this.translationService.translate(
          'public.commons.error.noStreamingAvailable',
          'Sorry, there are no streaming platforms currently available for ${this.country}.<br><small>Please check back later or try a different country.</small>',
          { country: this.translationService.translate(selectedCountry.trMsg, selectedCountry.name) }
        );
      } else {
        errorMsg = this.translationService.translate(
          'public.commons.error.changeCountry',
          'It looks like you are in ${this.country} and this page is for users in ${partnerTerritories}.<br><small>Change your country for new results.',
          {
            country: this.translationService.translate(selectedCountry.trMsg, selectedCountry.name),
            partnerTerritories
          }
        );
      }
    }
    return errorMsg;
  }

  determineTabsAvailability(movie: Movie, hideTabs?: TabType[]): TabType[] {
    const tabs: TabType[] = [];
    const placeholderAvailable = this.env.mode == 'develop' || this.env?.mode == 'local';
    const placeholderPerformancesGroup = placeholderAvailable
      ? this.env?.placeholderData?.performancesGroup
      : undefined;
    const placeholderStreamingLinks = placeholderAvailable ? this.env?.placeholderData?.streamingLinks : undefined;
    (this.determineInCinemasAvailability(movie) || placeholderPerformancesGroup) && tabs.push('in-cinemas');
    (this.determineStreamingAvailability(movie) || placeholderStreamingLinks) && tabs.push('watch-at-home');
    this.determineEnquiriesAvailability() && tabs.push('enquiries');
    this.determineFestivalsAvailability(movie) && tabs.push('at-festivals');
    if (hideTabs) {
      return tabs.filter(tab => !hideTabs.includes(tab));
    }
    return tabs;
  }

  areShowtimesAvailableAtChannelLevel(): boolean {
    if (this.channelService.channel.settings?.disableShowtimes) return false;
    return this.channelService.channel.userRoles.includes(UserRole.showtimes);
  }

  isStreamingAvailableAtChannelLevel(): boolean {
    if (this.channelService.channel.settings?.disableStreaming) return false;
    return this.channelService.channel.userRoles.includes(UserRole.streaming);
  }

  isDemandItFormEnabled(movie: Movie): boolean {
    if (!this.channelService.channel.commsConsumerDemandit) return false;
    if (this.env.showtimeStreamingConfig?.demandItOnShowtimeStreamingAbsence) {
      return this.determineDemandItOnShowtimeStreamingAbsence(movie);
    }
    return true;
  }

  private getEnableTabByAvailability(movie: Movie): TabType {
    const areShowtimesAvailableForCountry = this.areShowtimesAvailableForSelectedCountry(movie);
    const isStreamingAvailableForCountry = this.areStreamingLinksAvailableForSelectedCountry(movie);
    if (areShowtimesAvailableForCountry && isStreamingAvailableForCountry) return 'in-cinemas';
    if (areShowtimesAvailableForCountry) return 'in-cinemas';
    if (isStreamingAvailableForCountry) return 'watch-at-home';
    return 'in-cinemas';
  }

  private areStreamingLinksAvailableForSelectedCountry(movie: Movie): boolean {
    if (movie.disableStreaming) return false;
    if (this.isPlaceholderStreamingEnabled()) return true;
    if (movie?.hasPlayer) return true;
    return movie?.locStreamingAvailable;
  }

  /**
   * @docsNotRequired
   * @param movie showSubscription
   * @returns
   */
  private areShowtimesAvailableForSelectedCountry(movie: Movie): boolean {
    if (this.isPlaceholderShowtimesEnabled()) return true;
    const dayLong = 60 * 60 * 24 * 1000;
    return movie?.locCinemaEnd + dayLong > new Date().getTime();
  }

  private isPlaceholderStreamingEnabled(): boolean {
    if (this.env.mode == 'develop') {
      return this.env?.placeholderData?.streamingLinks ? true : false;
    }
    return false;
  }
  //
  private isPlaceholderShowtimesEnabled(): boolean {
    if (this.env.mode == 'develop') {
      return this.env?.placeholderData?.performancesGroup ? true : false;
    }
    return false;
  }

  private areShowtimesDisabledAtMovieLevel(movie: Movie): boolean {
    return movie.disableShowtimes;
  }

  private isStreamingDisabledAtMovieLevel(movie: Movie): boolean {
    return movie.disableStreaming;
  }

  private areShowtimesAvailableSomewhere(movie: Movie): boolean {
    return movie.globalInCinemas;
  }

  private areStreamingLinksAvailableSomewhere(movie: Movie): boolean {
    return movie.globalInStreaming;
  }

  private determineDemandItOnShowtimeStreamingAbsence(movie: Movie): boolean {
    if (!this.determineInCinemasAvailability(movie) && !this.determineStreamingAvailability(movie)) return true;
    return false;
  }
}
